<template>
    <Head :title="$t('auth.log_in')" />

    <ColumnsWithImage :heading="$t('auth.log_in')">
        <form class="space-y-6" @submit.prevent="submit">
            <TextInput
                v-model="form.email"
                type="email"
                name="email"
                required
                autofocus
                autocomplete="username"
                :placeholder="$t('auth.email')"
            >
                <template #leftIcon>
                    <EnvelopeIcon class="input-icon" />
                </template>
                <template #label>{{ $t('auth.email') }}</template>
                <template #error>{{ form.errors.email }}</template>
            </TextInput>

            <PasswordInput
                v-model="form.password"
                name="password"
                required
                autocomplete="password"
                :placeholder="$t('auth.password')"
            >
                <template #leftIcon>
                    <LockKeyIcon class="input-icon" />
                </template>
                <template #label>{{ $t('auth.password') }}</template>
                <template #error>{{ form.errors.password }}</template>
            </PasswordInput>

            <Checkbox
                v-model="form.remember"
                class="checkbox-primary"
                wrapper-classes="inline-block"
                checkbox-left
            >
                <template #label>{{ $t('auth.remember_me') }}</template>
                <template #error>{{ form.errors.remember }}</template>
            </Checkbox>
            <button
                class="btn btn-primary btn-block"
                :disabled="form.processing"
            >
                {{ $t('auth.log_in') }}
            </button>
        </form>
        <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="block link"
        >
            {{ $t('auth.forgot_password') }}
        </Link>
        <Link :href="route('register')" class="link">
            {{ $t('auth.no_account_yet') }}
            {{ $t('auth.register_here') }}
        </Link>

        <div v-if="environment === 'local'">
            <LoginLink
                email="admin@dotbite.at"
                label="Login as admin@dotbite.at"
            />
            <LoginLink
                email="user@dotbite.at"
                label="Login as user@dotbite.at"
            />
        </div>
    </ColumnsWithImage>
</template>

<script>
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import TextInput from '@/Components/TextInput.vue';
import PasswordInput from '@/Components/PasswordInput.vue';
import Checkbox from '@/Components/Checkbox.vue';
import ColumnsWithImage from './Partials/ColumnsWithImage.vue';
import EnvelopeIcon from '@assets/icons/EnvelopeSimple.svg?component';
import LockKeyIcon from '@assets/icons/LockKey.svg?component';
import LoginLink from '@/../../vendor/spatie/laravel-login-link/resources/js/login-link.vue';

export default {
    components: {
        Head,
        Link,
        TextInput,
        PasswordInput,
        Checkbox,
        ColumnsWithImage,
        EnvelopeIcon,
        LockKeyIcon,
        LoginLink,
    },
    layout: DefaultLayout,
    props: {
        canResetPassword: Boolean,
    },
    data() {
        return {
            form: useForm({
                email: '',
                password: '',
                remember: false,
            }),
        };
    },
    computed: {
        environment() {
            return usePage().props.config.environment;
        },
    },
    methods: {
        submit() {
            this.form.post(route('login'), {
                onFinish: () => this.form.reset('password'),
            });
        },
    },
};
</script>
